registerProcessor(
  'SequenceProcessor',
  class SequenceProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'reset',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;
    lastReset = 0;
    position = -1;
    sequence: Float32Array;

    constructor(options: AudioWorkletNodeOptions) {
      super(options);
      this.sequence = options.processorOptions.sequence;

      this.port.onmessage = ({ data: [type, value] }) => {
        switch (type) {
          case 'sequence':
            this.sequence = value;
            this.position =
              value.length === 0 ? 0 : this.position % value.length;
            break;
          default:
            throw new Error('unrecognized message');
        }
      };
    }

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      { clock: [clock], reset: [reset] }: { [param: string]: Float32Array },
    ): boolean {
      if (this.sequence.length > 0) {
        if (this.lastReset <= 0 && reset > 0) {
          this.position = 0;
          this.port.postMessage(['position', 0]);
        } else if (this.lastClock <= 0 && clock > 0) {
          this.position = (this.position + 1) % this.sequence.length;
          this.port.postMessage(['position', this.position]);
        }
        this.lastReset = reset;
        this.lastClock = clock;

        output.fill(this.sequence[this.position]);
      }

      return true;
    }
  },
);
